@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.footer {
  width: 100%;
  background-color: $col-white;

  &__relative {
    position: relative;
    @include column;
    justify-content: flex-end;
    overflow: hidden;
    padding: 0 1.5rem;
    background-image: url("../../../public/assets/images/footer.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    min-height: 65vh;
  }

  &__container {
    width: 100%;
    max-width: $max-md;
    margin: 5rem auto 1.5rem;
    padding: 0 1.5rem;
    font-size: $font-body;
    gap: 8rem;
    position: relative;

    @include column;
    align-items: center;
    justify-content: center;

    @include helvetica-light;

    @include respond(tab) {
      gap: 5rem;
      padding: 0;
    }
  }

  &__text {
    @include libre-regular;
    text-align: center;
    color: $col-black;
    font-size: $font-footer-header;
    max-width: 50rem;
    padding: 0 1.5rem;
  }

  &__image {
    position: absolute;
    background: linear-gradient(
      358.33deg,
      #3d5b57 51.07%,
      rgba(61, 91, 87, 0) 98.58%
    );
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__button {
    display: block;

    button {
      font-size: $font-body;

      @include respond(tab) {
        font-size: $font-footer-text;
      }
    }

    @include respond(tab) {
      display: none;
    }
  }

  &__middle {
    z-index: 2;
    @include flex-space-between;
    width: 100%;

    &__stack {
      @include column;
    }

    &__full-logo {
      width: fit-content;
      margin-bottom: 1rem;
    }

    &__hidden {
      overflow: hidden;
    }

    &__text {
      font-size: $font-body;
      color: $col-white;

      @include respond(tab) {
        font-size: $font-footer-text-2;
      }
    }
  }

  &__bottom {
    @include flex-space-between;
    flex-wrap: wrap-reverse;
    z-index: 2;
    width: 100%;
    gap: 0.5rem;

    @include respond(tab) {
      font-size: $font-footer-text-2;
      justify-content: center;
      flex-direction: column-reverse;
    }

    a {
      @include transition;

      &:hover {
        color: $col-orange;
      }
    }

    &__left {
      @include flex-row;
      gap: 1.5rem;
    }

    &__right {
      @include flex-row;
      gap: 1.5rem;
    }

    a {
      color: $col-white;
      text-align: center;
    }
  }

  &__hidden {
    overflow: hidden;
  }
}
