@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.button {
  width: fit-content;
  max-width: 100%;
  font-size: $font-button;

  &__primary {
    border-radius: $radius-sm;
    padding: 0.75rem 2.5rem;
    background: $col-green;
    border: 1px solid $col-green;

    @include flex-row;
    @include helvetica-light;
    @include transition;

    span {
      color: $col-white;
      white-space: nowrap;
    }

    &:hover {
      background: $col-green-2;
    }
  }

  &__secondary {
    border-radius: $radius-sm;
    padding: 0.75rem 2.5rem;
    border: 1px solid $col-orange-2;
    background: transparent;

    @include flex-row;
    @include helvetica-light;
    @include transition;

    span {
      color: $col-white;
    }

    &:hover {
      background: $col-orange-2;

      span {
        color: $col-black;
      }
    }
  }

  &__tertiary {
    border-radius: $radius-sm;
    padding: 0.75rem 2.5rem;

    @include flex-row;
    @include helvetica-light;

    span {
      color: $col-white;
    }
  }
}
