@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.header {
  width: 100%;
  z-index: 1;
  background: $col-white;

  &__container {
    width: 100%;
    max-width: $max-lg;
    margin: 0 auto;
    padding: 1.875rem;

    @include flex-space-between;

    @include helvetica-light;
  }

  &__logo {
    @include flex-row;
    width: 6rem;
  }

  &__hidden {
    overflow: hidden;

    &__mobile {
      display: none;

      @include respond(tab) {
        display: block;
      }
    }
  }

  &__group {
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    @include flex-row;

    @include respond(tab) {
      display: none;
    }

    &__login {
      padding: 0.75rem 1rem;
      border-radius: $radius-sm;
      white-space: nowrap;
      color: $col-black;

      @include button-hover-1($col-white, $col-green);
    }

    &__mobile {
      display: none;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      transition: all 0.2s ease-out;

      a {
        overflow: hidden;
      }

      @include respond(tab) {
        @include flex-column;
      }

      &:hover {
        filter: opacity(0.8);
      }

      &__line {
        width: 2rem;
        height: 0.15rem;
        border-radius: 4px;
        margin: 0.75rem 0;
        background-color: $col-black;
        position: relative;
        transition: all 0.2s ease-out;

        &::after,
        &::before {
          content: "";
          position: absolute;
          height: 0.15rem;
          border-radius: 4px;
          background-color: $col-black;
          transition: all 0.2s ease-out;
        }

        &::after {
          width: 1.5rem;
          transform: translate(0.5rem, -0.5rem);
        }

        &::before {
          width: 1rem;
          transform: translate(1rem, 0.5rem);
        }

        &__open {
          background-color: transparent;

          &::after {
            width: 2rem;
            transform: rotate(45deg) translate(5px, -5px);
          }

          &::before {
            width: 2rem;
            transform: rotate(-45deg) translate(5px, 5px);
          }
        }
      }
    }
  }
}
